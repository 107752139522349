<template>
  <div>
    <orSeparator style="max-width: 160px" class="mx-auto" />
    <div class="d-flex justify-center mt-4">
      <v-btn
        color="primary"
        outlined
        @click.stop="addArea(value.areas?.length + 1)"
      >
        {{ $t("inspections.addCategory") }}
      </v-btn>
    </div>
    <div class="subtitle-1 mb-3">{{ $t("inspections.categories") }}</div>
    <v-expansion-panels v-model="openAreas" multiple>
      <v-expansion-panel v-for="(area, i) in value.areas" :key="i">
        <v-expansion-panel-header>
          {{ area?.name }}
          <template v-slot:actions>
            <v-icon color="primary"> $expand </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            class="mb-3"
            v-model="area.name"
            label="Bezeichnung (Kategorie)"
            data-cy="areaName"
            dense
            hide-details
            :rules="config.rules"
          ></v-text-field>
          <div class="subtitle-2 mt-6 mb-3">
            {{ $t("tickets.navbar.tickets") }}
          </div>
          <v-expansion-panels v-model="openTasks" multiple>
            <v-expansion-panel v-for="(task, o) in area?.tasks" :key="o">
              <v-expansion-panel-header>
                {{ task?.name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field
                  class="mb-8"
                  v-model="task.name"
                  label="Bezeichnung (Aufgabe)"
                  data-cy="taskName"
                  dense
                  hide-details
                  :rules="config.rules"
                ></v-text-field>
                <div class="flex-grow-1"></div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      medium
                      class="mr-2"
                      v-on="on"
                      @click.stop="addTask(i, o)"
                      data-cy="delete"
                    >
                      add</v-icon
                    >
                  </template>
                  <span>{{ $t("tickets.navbar.addTicket") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      medium
                      class="mr-2"
                      v-on="on"
                      @click.stop="removeTask(i, o)"
                      data-cy="delete"
                    >
                      delete</v-icon
                    >
                  </template>
                  <span>{{ $t("tickets.navbar.deleteTicket") }}</span>
                </v-tooltip>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                medium
                class="mr-2 mt-3"
                v-on="on"
                @click.stop="addArea(i)"
                data-cy="delete"
              >
                add</v-icon
              >
            </template>
            <span>{{ $t("inspections.addCategory") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                medium
                class="mr-2 mt-3"
                v-on="on"
                @click.stop="removeArea(i)"
                data-cy="delete"
              >
                delete</v-icon
              >
            </template>
            <span>{{ $t("inspections.deleteCategory") }}</span>
          </v-tooltip>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import OrSeparator from "@/components/layout/orSeperator.vue";

export default {
  props: ["value", "config"],
  components: { OrSeparator },
  data: () => ({
    openAreas: [0],
    openTasks: [0],
  }),
  methods: {
    addArea(index) {
      this.value.areas.splice(index + 1, 0, {
        name: "",
        tasks: [{ name: "" }],
      });
    },
    removeArea(index) {
      this.value.areas.splice(index, 1);
    },
    addTask(areaIndex, index) {
      this.value.areas[areaIndex].tasks.splice(index + 1, 0, {
        name: "",
      });
    },
    removeTask(areaIndex, index) {
      this.value.areas[areaIndex].tasks.splice(index, 1);
    },
  },
};
</script>
